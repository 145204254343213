.intro-section {
    background-color: #0A0C11;
    background-position: center;
    background-size: cover;
    padding: 14rem 0 0;
    text-align: center;
    @media screen and (max-width : 575px) {
        padding: 18rem 0 0;
    }
    .intro__cost {
        margin: 2rem 0 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.4rem;
        font-weight: 600;
        text-align: left;
        img {
            margin-right: 2rem;
            @media screen and (max-width : 575px) {
                width: 25px;
            }
        }
        @media screen and (max-width : 767px) {
            .yellow {
                display: block;
            }
        }
    }
    .intro__main {
        display: flex;
        align-items: center;
        @media screen and (max-width : 767px) {
            display: block;
        }
        &-button {
            flex-shrink: 0;
            @media screen and (max-width : 767px) {
                display: inline-block;
                margin: 20px 0 40px;
            }
        }
        &-right {
            flex-grow: 1;
            margin-left: 4rem;
            text-align: left;
            font-size: 1.8rem;
            @media screen and (max-width : 767px) {
                font-size: 18px;
                margin-left: 0;
            }
            @media screen and (max-width : 575px) {
                font-size: 16px;
            }
            .yellow {
                font-weight: 600;
                font-size: 2.1rem;
                @media (max-width: 1230px) {
                    display: inline;
                }
                @media screen and (max-width : 767px) {
                    font-size: 20px;
                }
                @media screen and (max-width : 575px) {
                    font-size: 18px;
                }
            }
        }
    }
    .intro__content {
        max-width: 94rem;
        margin: 0 auto;
    }
    .intro__advantages {
        margin: 4.5rem 0 0;
        padding: 4rem 1.5rem 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-top: 1px solid #7E7E80;
        @media screen and (max-width : 767px) {
            flex-direction: column;
            text-align: left;
            justify-content: flex-start;
        }
        .advantage-item {
            position: relative;
            font-size: 16px;
            line-height: 24px;
            @media screen and (max-width : 767px) {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 5px;
                font-size: 18px;
                line-height: 1.3;
                br {
                    display: none;
                }
            }
            @media screen and (max-width : 575px) {
                font-size: 16px;
            }
            .dot {
                position: absolute;
                top: 100%;
                width: 10px;
                height: 10px;
                background: #FFC85B;
                border-radius: 50%;
                box-shadow: 0px 16px 46px 5px rgba(255, 200, 91, 0.4);
                transform: translateX(-50%);
                left: 50%;
                top: 98px;
                @media (max-width: 1400px) {
                    top: 85px;
                }
                @media screen and (max-width : 767px) {
                    position: static;
                    margin-right: 10px;
                    box-shadow: none;

                }
            }
        }
    }
    .kitchen {
        margin: 0 auto;
        margin-bottom: -6px;    
        @media (max-width: 1400px) {
            width: 1100px;
        }
        @media (max-width: 1230px) {
            width: 900px;
            margin-top: 12px;
        }
    }
}


.case-section {
    background-color: #080A12;
    padding: 7rem 0 0;
    text-align: center;
    position: relative;
    @media screen and (max-width : 575px) {
        padding: 10rem 0 0;
    }
    .case__book {
        position: absolute;
        top: -60%;
        left: -30rem;
        width: 49%;
        @media (max-width: 1700px) {
            top: -45%;
            left: -25rem;
        }
        @media screen and (max-width : 991px) {
            top: -25%;
            left: -17rem;
        }
        @media screen and (max-width : 560px) {
            top: -25%;
            left: -7rem;
        }
    }
    .case__knife {
        position: absolute;
        right: -4rem;
        top: -8%;
        width: 30%;
        @media (max-width: 1700px) {
            top: 0;
        }
        @media screen and (max-width : 991px) {
            top: 10%;
        }
        @media screen and (max-width : 560px) {
            top: 25%;
        }
    }
    .logo {
        width: 17rem;
    }
    .t-title {
        margin: 2rem 0;
    }
    .case__arrows {
        display: flex;
        align-items: center;
        justify-content: center;
        .arrow {
            background: #7D671D;
            background: linear-gradient(180deg, rgba(189,155,44,1) 0%, rgba(95,78,22,1) 100%);
            width: 53px;
            height: 57px;
            margin: 0 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px 0 0 30px;
            cursor: pointer;
            transition: 320ms;
            &.arrow-right {
                border-radius: 0 30px 30px 0;
                .img-svg {
                    transform: rotate(180deg);
                }
            }
            &:hover {
                background: linear-gradient(180deg, rgba(250,221,110,1) 0%, rgba(236,172,0,1) 100%);
            }
        }
    }

    .case__notice {
        font-size: 16px;
        margin-bottom: 3.5rem;
        padding-left: 28%;
        @media (max-width: 1400px) {
            padding-left: 24%;
            margin-top: 1.5rem;
        }
        @media (max-width: 1200px) {
            padding-left: 20%;
        }
        @media screen and (max-width : 767px) {
            padding: 0;
            margin-top: 15px;
            font-size: 14px;
        }
    }

    .case__slider {
        .case-space {
            outline: none;
            height: inherit;
            @media (min-width: 560px) {
                transform: scale(0.85);
                transition: 520ms;
                &.slick-current {
                    transform: scale(1.05);
                    opacity: 1;
                }
            }
        }
        .case-item {
            height: 48rem;
            width: 100%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
            @media (max-width: 1500px) {
                height: 38rem;
            }
            @media (max-width: 1230px) {
                height: 36rem;
            }
            @media screen and (max-width : 991px) {
                height: 30rem;
            }
            @media (max-width: 560px) {
                height: 35rem;
            }
        }
    }
}


.inner-content {
    background-color: #020206;
    background-size: contain;
    background-position: center 80rem;
    background-repeat: no-repeat;
    @media screen and (max-width : 575px) {
        background: #020206 !important;
    }
}


.trigger-section {
    text-align: center;
    padding: 11rem 0 5rem;
    position: relative;
    z-index: 4;
    .t-title {
        font-weight: 400;
        font-size: 4.2rem;
        line-height: 6.2rem;
    }
    .t-subtitle {
        margin: 2.5rem 0 4.5rem;
        color: #ffc85b;
        line-height: 1.4;
        @media screen and (max-width : 575px) {
            font-size: 2.4rem;
        }
    }
    .trigger__box {
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width : 767px) {
            display: block;
        }
        .trigger__item {
            margin: 0 5rem;
            display: flex;
            align-items: center;
            @media screen and (max-width : 991px) {
                margin: 0 2rem;
            }
            @media screen and (max-width : 767px) {
                margin: 0 0 30px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            img {
                @media screen and (max-width : 991px) {
                    max-width: 130px;
                }
                @media screen and (max-width : 575px) {
                    max-width: 100px;
                }
            }
            .text {
                font-size: 2.1rem;
                text-align: left;
                margin-left: 2rem;
                max-width: 230px;
                letter-spacing: 0.5px;
                @media screen and (max-width : 767px) {
                    font-size: 1.9rem;
                }
                @media screen and (max-width : 575px) {
                    font-size: 2.2rem;
                }
            }
        }
    }
}


.quiz-section {
    padding: 0 0 10rem;
    position: relative;
    .container {
        @media screen and (max-width : 575px) {
            padding: 0;
        }
    }

    .quiz__left {
        position: absolute;
        z-index: 4;
        left: 0%;
        top: -35%;
        max-width: 25%;
        @media (max-width: 1600px) {
            left: -8%;
            max-width: 23%;
        }
        @media (max-width: 1300px) {
            top: -53%;
            left: -4%;
            max-width: 20%;
        }
        @media screen and (max-width : 991px) {
            top: -38%;
            left: -6%;
            max-width: 21%;
        }
        @media screen and (max-width : 767px) {
            top: 66%;
            left: -6%;
            max-width: 26%;
        }
        @media screen and (max-width : 575px) {
            display: none;
        }
    }

    .quiz__right {
        position: absolute;
        z-index: 4;
        right: 3%;
        top: -36%;
        max-width: 23%;
        @media (max-width: 1600px) {
            right: -1%;
            max-width: 20%;
        }
        @media (max-width: 1300px) {
            right: -2%;
            max-width: 17%;
            top: -47%;
        }
        @media screen and (max-width : 991px) {
            top: -37%;
        }
        @media screen and (max-width : 767px) {
            top: 68%;
            max-width: 22%;
        }
        @media screen and (max-width : 575px) {
            display: none;
        }
    }

    .quiz__box {
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        border-radius: 10px;
        color: #000;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 21px 116.25px 38.75px rgba(0, 0, 0, 0.68);
        overflow: hidden;
        @media (min-width: 992px) {
            min-height: 660px;
        }
        @media (min-width: 1230px) {
            min-height: 705px;
        }
        @media (min-width:1400px) {
            min-height: 74rem;
        }
        @media screen and (max-width : 991px) {
            flex-wrap: wrap;
        }
        @media screen and (max-width : 575px) {
            border-radius: 0;
        }
    }
    .quiz__questions {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
    }
    .quiz__progress {
        display: none;
        padding: 0 20px;
        @media screen and (max-width : 767px) {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: 5rem 0 0;
        }
        .progress__line {
            background: #e5eaee;
            height: 1rem;
            border-radius: 15px;
            overflow: hidden;
            width: 13.9%;
            margin: 0;
        }
    }
    .quiz__steps {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        @media screen and (max-width : 767px) {
            display: none;
        }
        .quiz__step {
            width: 16.6666%;
            height: 13rem;
            border-bottom: 1px solid #BDBDBD;
            border-right: 1px solid #BDBDBD;
            text-align: center;
            padding-top: 3.6rem;
            &:last-child {
                border-right: 0;
            }
            .title {
                font-size: 2.1rem;
                font-weight: 600;
            }
            .subtitle {
                font-size: 1.6rem;
                margin-top: 1rem;
            }
        }
    }
    .quiz__questions-content {
        padding: 5rem 3rem 6rem;
        flex-grow: 1;
        min-height: 61rem;
        @media screen and (max-width : 575px) {
            padding: 5rem 15px;
        }
        .step {
            display: none;
            width: 100%;  
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
        }
        .ques-title {
            font-weight: 600;
            font-size: 2.4rem;
            margin-bottom: 4rem;
            @media screen and (max-width : 991px) {
                text-align: center;
                font-size: 2.8rem;
            }
        }
    }

    .simple__box {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: center;
        @media screen and (max-width : 991px) {
            justify-content: space-between;
            flex-wrap: wrap;
        }
        &.active {
            display: flex !important;
        }
        .simple__item {
            width: 24%;
            margin-right: 1.5%;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
            &:hover, &.focus {
                .simple-img, .simple-title {
                    opacity: 1;
                }
            }
            @media screen and (max-width : 991px) {
                width: 47%;
                margin: 0 0 0;
            }
        }
        .simple-img {
            opacity: 0.44;
            transition: 220ms;
        }
        .simple-title {
            transition: 220ms;
            opacity: 0.44;
            font-size: 2.1rem;
            font-weight: 600;
            position: relative;
            top: -7rem;
            @media screen and (max-width : 575px) {
                font-size: 2.3rem;
            }
            @media (max-width: 1230px) {
                &.small-xs {
                    font-size: 1.8rem;
                    @media screen and (max-width : 575px) {
                        font-size: 2.1rem;
                    }
                }
            }
        }
        .simple-subtitle {
            font-size: 1.6rem;
            color: #434343;
            position: relative;
            top: -6.5rem;
            opacity: 0.7;
        }
        .simple-input {
            position: relative;
            top: -4rem;
            border-width: 3px;
            border-color: rgb(229, 229, 229);
            border-style: solid;
            border-radius: 4px;
            background-color: rgb(255, 255, 255);
            width: 120px;
            height: 40px;
            font-size: 16px;
            text-align: center;
            outline: none;
            &:hover, &:focus {
                border-color:#ffe77f;
            }
            &::placeholder {
            }
        }
        .simple-radio {
            display: none;
            &:checked {
                & ~ .simple-img, & ~ .simple-title {
                    opacity: 1;
                }
            }
        }
    }

    .small__box {
        display: flex;
        align-self: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
        @media screen and (max-width : 991px) {
            justify-content: space-between;
        }
        .w100 {
            @media screen and (max-width : 991px) {
                display: none;
            }
        }
        .small__item {
            width: 24%;
            margin-bottom: .5%;
            margin-right: 1%;
            position: relative;
            cursor: pointer;
            transition: 220ms;
            @media screen and (max-width : 991px) {
                width: 48%;
                margin: 0 0 4%;
            }
            @media screen and (max-width : 575px) {
                width: 49%;
                margin: 0 0 2%;
            }
            &:last-child {
                margin-right: 0;
            }
            .small-img {
                border-radius: 15px;
                border: 4px solid transparent;
                width: 100%;
            }
            .small-title {
                position: absolute;
                font-size: 16px;
                font-weight: 600;
                color: #fff;
                bottom: 3rem;
                left: 0;
                text-align: center;
                width: 100%;
                padding-left: 2rem;
                padding-right: 2rem;
                @media screen and (max-width : 575px) {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            &:hover {
                .small-img {
                    border-color: #FFB324;
                }
            }
        }
        &-top {
            .small-title {
                bottom: auto;
                top: 2.5rem;
                font-size: 1.7rem;
            }
        }
        .small-radio {
            display: none;
            &:checked {
                & ~ .small-img {
                    border-color: #FFB324;
                }
            }
        }
    }
    
    .big__box {
        display: flex;
        align-self: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        .big__item {
            width: 32%;
            cursor: pointer;
            @media screen and (max-width : 991px) {
                width: 48%;
                margin: 0 0 4%;
            }
            @media screen and (max-width : 575px) {
                width: 48%;
                margin: 0 0 30px;
            }
        }
        .big__flex {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 2rem 0 0;
        }
        img {
            width: 100%;
        }
        .label-radio {
            margin-right: 1.5rem;
            width: 25px;;
            height: 25px;;
            border-radius: 50%;
            border: 4px solid #E0E6E8;
            position: relative;
            padding: 2px;
            flex-shrink: 0;
            span {
                border-radius: 50%;
                display: block;
                height: 100%;
                background: #FFB324;
                transition: 220ms;
                opacity: 0;
            }
        }
        .big-title {
            font-size: 16px;
            font-weight: 600;
        }
        .big-radio {
            display: none;
            &:checked {
                & ~ .big__flex .label-radio span {
                    opacity: 1;
                }
            }
        }
    }
    .step__navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 2rem;
        margin-top: 3rem;
        @media screen and (max-width : 767px) {
            flex-direction: column;
        }
        .prev-btn {
            cursor: pointer;
            font-size: 13px;
            color: #b1bbcb;
            text-transform: uppercase;
            font-weight: 600;
            position: relative;
            transition: 220ms;
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 1.5px;
                background: #b1bbcb;
                bottom: -3px;
                opacity: 0.7;
                transition: 220ms;
            }
            &:hover {
                color: #000;
                &::before {
                    background: #000;
                    opacity: 1;
                }
            }
        }
        .step__left {
            @media screen and (max-width : 767px) {
                order: 1;
                margin: 30px 0 0;
            }
        }
        .step__right {
            display: flex;
            align-items: center;
            @media screen and (max-width : 767px) {
                order: 0;
            }
        }
        .additional-btn {
            margin-right: 20px;
            font-weight: 600;
            font-size: 1.8rem;
            text-decoration: underline;
            cursor: pointer;
            color: #1e1e1e;
            &:hover {
                color: #000;
                text-decoration: none;
            }
        }
        .t-btn {
            width: 20rem;
            height: 7rem;
            border: 0;
            border-radius: 35px;
            @media screen and (max-width : 575px) {
                width: 24rem;
                height: 9rem;
            }
        }
    }

    .quiz__advantages {
        padding: 4rem 3rem;
        width: 33rem;
        flex-shrink: 0;
        background-size: 100% 100%;
        text-align: center;
        overflow: hidden;
        position: relative;
        @media screen and (max-width : 991px) {
            width: 100%;
            background: linear-gradient( 90deg,rgb(255,198,0) 0%, rgb(255,224,107) 100%) !important;
        }
        .quiz__advantages-bottom {
            @media screen and (max-width : 991px) {
                display: flex;
                width: 100%;
                justify-content: space-around;
                align-items: flex-end;
            }
        }
        .title1, .title2 {
            font-size: 2.1rem;
            font-weight: 700;
            @media screen and (max-width : 767px) {
                font-size: 2.8rem;
            }
        }
        .title1 {
            display: inline;
        }
        .title2 {display: none;}
        .subtitle {
            font-size: 2.1rem;
            margin-top: 4px;
            display: none;
            justify-content: center;
            @media screen and (max-width : 767px) {
                font-size: 2.84rem;
            }
        }
        &-item {
            margin-top: 4rem;
            text-align: center;
            @media screen and (max-width : 991px) {
                width: 50%;
            }
            .item-title {
                font-size: 1.6rem;
                max-width: 210px;
                margin: 0 auto;
                @media screen and (max-width : 991px) {
                    font-size: 1.8rem;
                }
                @media screen and (max-width : 767px) {
                    font-size: 2.1rem;
                }
            }
            @media(min-width: 992px) {
                margin-top: 2rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                img {
                    width: 8rem;
                }
                .item-title {
                    text-align: left;
                    margin: 0;
                    margin-left: 2rem;
                }
            }
        }
    }
    // 

    .quiz1 {
        .step1 {
            display: flex;
        }
        .quiz__advantages .step1 {
            display: inline;
        }
        .quiz__step {
            border-right: 0;
            border-left: 1px solid #BDBDBD;
        }
        .quiz__step1 {
            background-color: rgb(255, 231, 127);
            height: 12rem;
            border-left: 0;
        }
        .line1 {
            background: #39C543 url("../img/icons/progress.png") repeat-x;
            animation: moving 1s linear infinite;
        }
    }

    .quiz2 {
        .step2 {
            display: flex;
            .simple__box {
                display: none;
            }
        }
        .quiz__advantages .step2 {
            display: inline;
        }
        .quiz__step2 {
            background-color: rgb(255, 231, 127);
            height: 12rem;
            border-left: 0;
            border-right: 0;
        }
        .quiz__step3 {
            border-left: 1px solid #BDBDBD;
        }
        .line1, .line2 {
            background: #39C543 url("../img/icons/progress.png") repeat-x;
            animation: moving 1s linear infinite;
        }
    }

    .quiz3 {
        .step3 {
            display: flex;
        }
        .quiz__advantages .step3{
            display: inline;
        }
        .quiz__step3 {
            background-color: rgb(255, 231, 127);
            height: 12rem;
            border-left: 0;
            border-right: 0;
        }
        .quiz__step4 {
            border-left: 1px solid #BDBDBD;
        }
        .line1, .line2, .line3 {
            background: #39C543 url("../img/icons/progress.png") repeat-x;
            animation: moving 1s linear infinite;
        }
    }

    .quiz4 {
        .step4 {
            display: flex;
        }
        .quiz__advantages .step4 {
            display: inline;
        }
        .quiz__step4 {
            background-color: rgb(255, 231, 127);
            height: 12rem;
            border-left: 0;
            border-right: 0;
        }
        .quiz__step5 {
            border-left: 1px solid #BDBDBD;
        }
        .line1, .line2, .line3, .line4 {
            background: #39C543 url("../img/icons/progress.png") repeat-x;
            animation: moving 1s linear infinite;
        }
    }

    .quiz5 {
        .step5 {
            display: flex;
        }
        .quiz__advantages .step5 {
            display: inline;
        }
        .quiz__step5 {
            background-color: rgb(255, 231, 127);
            height: 12rem;
            border-left: 0;
            border-right: 0;
        }
        .quiz__step6 {
            border-left: 1px solid #BDBDBD;
        }
        .line1, .line2, .line3, .line4, .line5 {
            background: #39C543 url("../img/icons/progress.png") repeat-x;
            animation: moving 1s linear infinite;
        }
    }

    .quiz6 {
        .step6 {
            display: flex;
        }
        .quiz__advantages .step6 {
            display: inline;
        }
        .quiz__step6 {
            background-color: rgb(255, 231, 127);
            height: 12rem;
            border-left: 0;
            border-right: 0;
        }
        .line1, .line2, .line3, .line4, .line5, .line6 {
            background: #39C543 url("../img/icons/progress.png") repeat-x;
            animation: moving 1s linear infinite;
        }
    }


    // Итог

    .quiz__cost, .quiz__form {
        display: none;
        padding: 7.5rem 0 0;
    }

    .quiz__cost {
        width: 35rem;
        flex-shrink: 0;
        text-align: center;
        background-color: #F1F1F1;
        position: relative;
        @media screen and (max-width : 991px) {
            width: 50%;
        }
        @media screen and (max-width : 767px) {
            width: 100%;
            padding: 5rem 3rem 2rem !important;
        }
        .title {
            font-size: 21px;
            font-weight: 700;
            margin: 0;
            position: relative;
            z-index: 4;
            @media screen and (max-width : 575px) {
                line-height: 1.2;
            }
        }
        .cost {
            font-size: 14.7rem;
            font-weight: 700;
            position: relative;
            z-index: 4;
            margin: -2rem 0 0;
            @media screen and (max-width : 575px) {
                font-size: 12rem;
            }
        }
        .cost-image {
            position: absolute;
            max-width: 150%;
            top: 11rem;
            left: -24%;
            @media screen and (max-width : 991px) {
                max-width: 103%;
                top: 16rem;
                left: 1%;
            }
            @media screen and (max-width : 767px) {
                display: none;
            }
        }
    }

    .quiz__form {
        flex-grow: 1;
        padding-left: 5.5rem;
        padding-right: 2.5rem;
        padding-bottom: 4rem;
        position: relative;
        z-index: 4;
        @media screen and (max-width : 991px) {
            padding-bottom: 10rem;
        }
        @media screen and (max-width : 767px) {
            padding-right: 5.5rem;
        }
        @media screen and (max-width : 575px) {
            padding: 4rem 5rem 50px;
        }
        @media screen and (max-width : 360px) {
            padding: 4rem 15px 50px;
            
        }
        .title {
            font-size: 2.4rem;
            font-weight: 600;
        }
        .subtitle {
            font-size: 16px;
            margin: 1rem 0 2rem;
        }
        .label__checkbox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            font-size: 14px;
            margin-bottom: 8px;
            .label__reactangle {
                width: 25px;
                height: 25px;
                flex-shrink: 0;

                margin-right: 1.3rem;
                border-radius: 2px;
                background-color: rgb(240, 240, 240);
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 220ms;
                img {
                    width: 55%;
                    opacity: 0;
                    transition: 220ms;
                }
            }
            &:hover {
                .label__reactangle {
                    background: darken(rgb(240, 240, 240), 5%);
                }
            }
            input {
                display: none;
                &:checked ~ .label__reactangle img {
                    opacity: 1;
                }
            }
        }
        .space {
            height: 20px;
        }
        .form__inner {
            width: 30rem;
            @media screen and (max-width : 991px) {
                width: 35rem;
            }
            @media screen and (max-width : 767px) {
                width: 100%;
            }
            .t-input {
                input {
                    @media screen and (max-width : 575px) {
                        padding: 4rem 7rem;
                    }
                }
            }
            button {
                width: 100%;
                @media (max-width: 1230px) {
                    span {
                        font-size: 11px;
                        @media screen and (max-width : 575px) {
                            font-size: 2.2rem;
                        }
                    }
                }
            }
        }
        .privacy__warning {
            padding-left: 30px;
            text-align: left;
            margin-top: 2rem;
        }

    }

    .itog {
        .quiz__cost, .quiz__form {
            display: block;
        }
        .title1, .quiz__questions, .quiz__progress {
            display: none;
        }
        .quiz__advantages {
            background: linear-gradient( 90deg,rgb(255,198,0) 0%, rgb(255,224,107) 100%) !important;
            width: 35rem;
            .quiz__advantages-bottom {
                @media screen and (max-width : 991px) {
                    align-items: flex-end;
                }
            }
            @media screen and (max-width : 991px) {
                width: 50%;
            }
            @media screen and (max-width : 767px) {
                width: 100%;
            }
            .quiz__shadow {
                @media screen and (max-width : 991px) {
                    display: none;
                }
            }
            .subtitle {
                display: none;
            }
            .title2 {
                display: block;
            }
        }
    }



}


.thanks-section {
    text-align: left;
    margin: 7rem 0 0;
    padding-bottom: 13rem;
    position: relative;
    flex-grow: 1;
    
    .intro__flex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-left: 10px;
        position: relative;
        z-index: 3;
        margin-bottom: 4rem;
    }
    .intro__left {
        .t-title {
            text-align: left;
            @media screen and (max-width : 575px) {
                font-size: 5rem;
            }
        }
        .t-subtitle {
            font-size: 2.2rem;
            font-weight: 600;
            margin-top: 2rem;
            @media screen and (max-width : 991px) {
                br {
                    display: none;
                }
            }
            @media screen and (max-width : 575px) {
                font-size: 2.6rem;
                font-weight: 500;
            }
        }
    }
    .intro__right {
        @media screen and (max-width : 767px) {
            display: none;
        }
        img {
            margin-bottom: -6rem;
        }
    }

    .thanks__box {
        display: flex;
        align-items: stretch;
        border-radius: 10px;
        overflow: hidden;
        color: #000;
        flex-wrap: wrap;
        .thanks__left, .thanks__right {
            width: 50%;
            padding: 4rem 3.5rem 2.5rem;
            padding-right: 2rem;
            @media screen and (max-width : 991px) {
                width: 100%;
            }
        }
        .thanks__left {
            background: #F3F3F3;
        }
        .thanks__right {
            background: #E6E6E6;
            padding-bottom: 3.5rem;
            padding-right: 3.5rem;
        }
        .title {
            font-size: 2.1rem;
            font-weight: 600;
            margin-bottom: 2rem;
            @media screen and (max-width : 991px) {
                font-size: 21px;
            }
            @media screen and (max-width : 767px) {
                font-size: 19px;
                br {
                    display: none;
                }
            }
        }
        .text {
            font-size: 1.6rem;
            color: #666768;
            @media screen and (max-width : 991px) {
                font-size: 16px;
            }
            @media screen and (max-width : 767px) {
                font-size: 15px;
                br {
                    display: none;
                }
            }
        }
        .thanks__pdf {
            margin: 20px 0 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            transition: 220ms;
            &:hover .text {
                color: #FF7246;
            }
            .text {
                @media screen and (max-width : 575px) {
                    font-size: 14px;
                }
            }
            img {
                margin-right: 1.5rem;
            }
        }
        .messenger-label {
            background: #fff;
            width: auto;
            padding: 10px 20px;
            justify-content: center;    
            @media screen and (max-width : 575px) {
                font-size: 16px;
            }
        }
        .thanks__right-box {
            margin-top: 4rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width : 575px) {
                flex-direction: column;
                align-items: center;
            }
        }
        .director {
            display: flex;
            align-items: center;
            text-align: right;
            color: #666768;
            @media screen and (max-width : 575px) {
                margin-top: 20px;
            }
            img {
                margin-right: 2rem;
            }
            .name {
                font-size: 1.8rem;
                font-weight: 500;
                font-style: italic;
                @media screen and (max-width : 575px) {
                    font-size: 16px;
                }
            }
            .position {
                font-size: 1.6rem;
                @media screen and (max-width : 575px) {
                    font-size: 14px;
                }
            }
        }
    }

    .thanks__book {
        position: absolute;
        bottom: -33rem;
        left: -25rem;
        max-width: 55%;
        @media (max-width: 1400px) {
            bottom: -25%;
        }
        @media screen and (max-width : 991px) {
            bottom: -10%;
            max-width: 65%;
        }
        @media screen and (max-width : 560px) {
            bottom: 0;
            max-width: 100%;
        }
        &.thanks__book2 {
            bottom: -15rem;
            @media (max-width: 1400px) {
                bottom: -5rem;
            }
            @media screen and (max-width : 991px) {
                bottom: 0%;
                max-width: 65%;
            }
            @media screen and (max-width : 767px) {
                display: none;
            }
        }
    }
    .thanks__knife {
        position: absolute;
        right: -4rem;
        bottom: -12%;
        width: 30%;
        @media (max-width: 1400px) {
            bottom: -8%;
        }
        @media (max-width: 991px) {
            width: 40%;
            right: -10rem;
        }
        &.thanks__knife2 {
            bottom: -5%;
            @media screen and (max-width : 991px) {
            bottom: 0;
            }
            @media screen and (max-width : 767px) {
                display: none;
            }
        }
    }
}