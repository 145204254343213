
.overlay {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0,0,0,0.7);

    max-height: 100%;
    overflow-y: auto;
    align-items: center;
    justify-content: center;
}

.modal {
    display: none;
    z-index: 100;
    position: fixed;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);

    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    color: #000;
    transform: scale(0.90);
    .modal__title {
        font-size: 4.8rem;
        font-weight: 600;
        color: #2b2b2b;
    }
    .modal__subtitle {
        font-size: 2.1rem;
        font-weight: 500;
    }
    .modal__description {
        opacity: 0.8;
        color: #2b2b2b;
        font-size: 1.8rem;
    }
    @media (max-height: 800px) {
        transform: scale(0.75);
    }
    @media screen and (max-width : 991px) {
        transform: 1;
    }
}

.modal__close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    @media screen and (max-width : 575px) {
        right: 15px;
        top: 15px;
    }
    .img-svg {
        width: 23px;
        height: 23px;
        path {
            fill: #CDCDCD;
        }
    }
    &:hover {
        .img-svg path {
            fill: #CCD5F2;
        }
    }
}

.privacy__warning {
    font-size: 12px;
    color: #9c9c9c;
    margin: 1.5rem 0 0;
    text-align: center;
    display: inline-block;
    .img-svg {
        width: 17px;
        margin-right: 8px;
        .shield-main {
            fill: #9c9c9c;
        }
    }
    .text {
        display: inline;
        position: relative;
        .img-svg {
            position: absolute;
            top: -4px;
            left: -25px;
        }
    }
    a {
        text-decoration: underline;
        color: #003397;
    }
}
.messenger-links {
    margin: 1.5rem 0 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}
.messenger-label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    color: #000;
    background-color: rgb(240, 240, 240);
    border-radius: 30px;
    padding: 1rem 2rem;
    width: 32.5%;
    transition: 220ms;
    cursor: pointer;
    @media screen and (max-width : 575px) {
        font-size: 13px;
    }
    @media screen and (max-width : 360px) {
        font-size: 11px;
    }
    &:hover, &.active {
        background: #FADD6E;
    }
    input {
        display: none;
    }
    img {
        margin-right: 12px;
        width: 25px;
        @media screen and (max-width : 991px) {
            width: 20px;
        }
        @media screen and (max-width : 575px) {
            width: 18px;
        }
        @media screen and (max-width : 360px) {
            width: 15px;
        }
    }
}

.callback {
    max-width: 58rem;
    width: 100%;
    padding: 4.5rem 8rem;
    text-align: center;
    @media screen and (max-width : 991px) {
        // max-width: 70rem;
    }
    @media screen and (max-width : 575px) {
        max-width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        transform: none;
        border-radius: 0;
        padding: 4rem 4rem;
    }
    .yellow-notice {
        display: none;
    }
    .modal__title {
        margin: 0 0 0.5rem;
        font-size: 3.5rem;
        line-height: 1.2;
    }
    .modal__subtitle {
        margin-bottom: 2.5rem;
        font-size: 2rem;
    }
    .modal__description {
        opacity: 1;
        color: #000;
        margin-top: 2rem;
        &.second {
            margin: 3.5rem 0 2rem;
        }
    }
    .modal__phone {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        margin: 1rem 0 0;
        display: inline-block;
        color: #2b2b2b;
        opacity: 0.8;
        @media screen and (max-width : 575px) {
            font-size: 18px;
        }
        &:hover {
            opacity: 1;
        }
    }
}


.big {
    padding: 0;
    width: 100%;
    @media (min-width: 768px) and (max-width: 991px) {
        max-width: 623px;
    }
    @media screen and (max-width : 575px) {
        max-width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        transform: none;
        border-radius: 0;
    }
    .modal__close {
        top: 2.5rem;
        right: 2.5rem;
        opacity: 1;
        z-index: 5;
        .img-svg {
            width: 3.2rem;
            height: 3.2rem;
        }
        .img-svg path {
            fill: #CDCDCD;
        }
        &:hover {
            .img-svg path {
                fill: #000;
            }
        }
    }
    .modal__content {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 991px) {
            flex-wrap: wrap;
        }
        @media screen and (max-width : 575px) {
            align-items: center;
            height: 100%;
        }
    }
    .modal__title {
        font-size: 4.4rem;
        line-height: 5.6rem;
        margin: 2rem 0 1.5rem;
        @media screen and (max-width : 991px) {
            br {
                display: none;
            }
        }
        @media screen and (max-width : 575px) {
            font-size: 4rem;
            line-height: 5.3rem;
        }
    }
    .big__left {
        padding: 5.5rem 5.5rem 6rem;
        padding-right: 3rem;
        width: 52%;
        overflow-y: auto;
        @media screen and (max-width : 991px) {
            width: 100%;
            padding: 8rem 10rem;
            max-height: 95vh;
        }
        @media screen and (max-width : 767px) {
            padding: 5.5rem 6rem;
        }
        @media screen and (max-width : 575px) {
            padding: 5.5rem 3rem;
        }
    }
    .messenger-links {
        margin-bottom: 10px;
    }
    .form-inner {
        padding-right: 7rem;
        @media screen and (max-width : 991px) {
            padding: 0;
        }
    }
    .privacy__warning {
        padding-left: 3rem;
    }
    .t-btn {
        .download-icon {
            z-index: 4;
            position: absolute;
            left: 1rem;
            top: 53%;    
            transform: translateY(-50%);
            height: 80%;
        }
        .button-bg {
            z-index: 4;
            position: absolute;
            bottom: -2px;
            right: -2px;
            max-width: 20.5rem;
        }
    }
    ul {
        padding: 0;
        margin-bottom: 4rem;
        li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 1.8rem;
            margin-bottom: 1rem;
            @media screen and (max-width : 575px) {
                font-size: 15px;
            }
            img {
                margin-right: 1.5rem;
                width: 11px;
            }
        }
    }
    .big__right {
        flex-grow: 1;
        background-color: rgb(241, 241, 241);
        position: relative;
        z-index: 4;
        border-radius: 0 10px 10px 0;
        pointer-events: none;
        .lifehack_top {
            position: absolute;
            top: -35%;
            left: -30%;
            z-index: 4;
            img {
                max-width: 103rem;
            }
            @media (max-width: 1230px) {
                top: -30%;
            }
        }

        .inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
        .lifehack_bottom {
            position: absolute;
            bottom: -5%;
            right: -5%;
            max-width: 130%;
        }
    }
}
.catalog {
    .big__right {
        .lifehack_top {
            top: -38%;
            left: -25%;
            img {
                max-width: 95rem;
            }
            @media (max-width: 1230px) {
                top: -30%;
            }
        }
        .lifehack_bottom {
            max-width: 115%;
        }
    }
}