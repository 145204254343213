header {
    position: absolute;
    z-index: 30;
    left: 0;
    top: 0;
    width: 100%;
    padding: 2rem 0 1rem;
    overflow: hidden;
    color: #fff;
    transition: 220ms;
    .container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @media screen and (max-width : 991px) {
            max-width: 95%;
        }
    }
    &.fixed {
        position: fixed;
        background: #111317;
        .header__logo-description {
            display: none !important;
        }
        .header__menu-btn {
            margin-top: -4px;
        }
    }
}

.header__logo {
    flex-shrink: 0;
    margin-top: -7px;
    .header__logo-img {
        width: 22rem;
        @media screen and (max-width : 991px) {
            width: 19rem;
        }
        @media screen and (max-width : 575px) {
            width: 190px;
        }
    }
    .header__logo-description {
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: .5rem;
        @media screen and (max-width : 991px) {
            font-size: 10px;
            line-height: 16px;
        }
    }
}

.header__catalog {
    display: flex;
    align-items: center;
    span {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 11px;
    }
    &:hover {
        color: #FFE684;
    }
    @media (max-width: 920px) {
        display: none;
    }
}

.header__title {
    font-size: 12px;
    margin-bottom: 5px;
}
.header__messenger {
    flex-shrink: 0;
    @media screen and (max-width : 575px) {
        display: none;
    }
    .d-flex {
        margin-top: 16px;
        display: flex;
        align-items: center;
        @media screen and (max-width : 575px) {
            margin-top: 12px;
        }
    }
}
.messenger-btn {
    display: flex;
    align-items: center;
    margin-right: 3rem;
    &:last-child {
        margin-right: 0;
    }
    .img-svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
    span {
        font-size: 10px;
    }
    &:hover {
        color: #FFE684;
    }
}
.header__email {
    padding: 0 10px;
    .header__title {
        margin-bottom: 1.2rem;
    }
    @media (max-width: 1230px) {
        display: none;
    }
    a {
        font-size: 10px;
        letter-spacing: 0.5px;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
            color: #FFE684;
        }
    }
}
.header__social {
    @media screen and (max-width : 991px) {
        display: none;
    }
    .header__title {
        margin-bottom: 1.7rem;
    }
    .d-flex {
        align-items: center;
        a {
            margin-right: 10px;
        }
    }
    .img-svg {
        width: 20px;
        height: 20px;
        path {
            fill: #fff;
        }
        &:hover {
            path {
                fill: #FFE684;
            }
        }
    }
}
.header__status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    .green {
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin-right: 10px;
        animation: glowing 1000ms infinite;
        &.offline {
            animation: red 1000ms infinite;
        }
    }
}

@keyframes red {
    0% { background: transparent; }
    50% { background: #FF0800; }
    100% { background: transparent; }
}

@keyframes glowing {
    0% { background: transparent; }
    50% { background: rgb(0, 162, 4); }
    100% { background: transparent; }
}
.header__phone {
    text-align: right;
    @media screen and (max-width : 720px) {
        display: none;
    }
    .phone-link {
        white-space: nowrap;
        font-size: 2.1rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        .img-svg {
            margin-right: 12px;
        }
    }
    .modal__open, .text {
        font-size: 12px;
        margin-top: 3px;
        letter-spacing: 0.5px;
        &:hover {
            color: #FFE684;
        }
    }
}

.header__menu-btn {
    display: none;
    @media (max-width: 991px) {
        display: block;
        margin-left: 3rem;
        cursor: pointer;
        .img-svg {
            width: 40px;
            height: 40px;
            path {
                fill: #fff;
            }
        }
    }
}