@import "../../local_modules/normalize.css/normalize";

*,
*::before,
*::after {
    box-sizing: border-box;
	webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color:  transparent;
}

html, body {
    width: 100%;
    overflow-x: hidden;
}

html {
    margin-right: calc(-1 * (100vw - 100%));
    font-size: 6px;
    height: 100%;
}

body {
    background: #0D0E12;
    font-size: 1.6rem;
    line-height: 1.4;

    font-family: 'Gilroy'; 
    font-weight: 400;
    color: #fff;

    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

}

.my-body-noscroll-class {
    overflow: hidden; }

img {
    max-width: 100%;
}
b {
    font-weight: 700;
}
a, button, .img-svg, .modal__open {
    outline: none;
    border: 0;
    cursor: pointer;
    background: none;
    transition: 220ms;
    text-decoration: none;
}
a {
    color: #fff;
}


/* Bootstrap 4 */
.container {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    width: 100%;
}

@media (min-width: 360px) {
    html {
        font-size: 6.5px;
    }
}

@media (min-width: 576px) {
    html {
        font-size: 8.5px;
    }
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width:1230px) {
    html {
        font-size: 9px;
    }
    .container {
        max-width: 1200px;
    }
}


@media (min-width:1400px) {
    html {
        font-size: 10px;
    }
}

.hidden-xs {
    @media screen and (max-width : 767px) {
        display: none;
    }
}
.hidden-lg {
    @media screen and (min-width : 767px) {
        display: none;
    }
}

.btn-center {
    text-align: center;
}
.d-flex {
    display: flex;
}
.blick-btn {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    background-size: 1px 100%;
    color: #535151;
    position: relative;
    z-index: 3;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    overflow: hidden;
    cursor: pointer;
}
.blick-btn:after {
    content: "";
    top: -50%;
    right: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(229, 172, 142, 0)), color-stop(50%, rgba(255, 255, 255, 0.7)), to(rgba(229, 172, 142, 0)));
    background: -o-linear-gradient(top, rgba(229, 172, 142, 0), rgba(255, 255, 255, 0.7) 50%, rgba(229, 172, 142, 0));
    background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(255, 255, 255, 0.7) 50%, rgba(229, 172, 142, 0));
    -webkit-transform: rotateZ(60deg) translate(-5em, 7.5em);
    -ms-transform: rotate(60deg) translate(-5em, 7.5em);
    transform: rotateZ(60deg) translate(-5em, 7.5em);
    opacity: 0;
    position: absolute;
    z-index: 3;
    -webkit-animation: sheens 3s infinite;
    animation: sheens 3s infinite;
}
@keyframes sheens {
    0%,79% {
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transform: rotateZ(60deg) translate(-5em,7.5em);
        transform: rotateZ(60deg) translate(-5em,7.5em)
    }

    80% {
        opacity: 1;
        filter: alpha(opacity=100);
        -webkit-transform: rotateZ(60deg) translate(-5em,7.5em);
        transform: rotateZ(60deg) translate(-5em,7.5em)
    }

    95% {
        opacity: 1;
        filter: alpha(opacity=100);
        -webkit-transform: rotateZ(60deg) translate(1em,-11em);
        transform: rotateZ(60deg) translate(1em,-11em)
    }

    to {
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transform: rotateZ(60deg) translate(1em,-9em);
        transform: rotateZ(60deg) translate(1em,-9em)
    }
}

.slick-slide {
    outline: none !important;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0;
}


.yellow {
    color: #ffe77f;
}

.mobile-fast-aos {
    @media screen and (max-width : 575px) {
        transform: translateZ(0) !important;
        opacity: 1 !important;
    }
}


.line {
    display: block;
    margin: 10px auto;
}
.t-subtitle {
    font-size: 2.1rem;
}
.t-title {
    text-align: center;
    font-size: 5rem;
    line-height: 6rem;
    font-weight: 600;
    position: relative;
    z-index: 4;
    @media screen and (max-width : 991px) {
        font-size: 4rem;
        line-height: 5rem;
    }
    @media screen and (max-width : 767px) {
        font-size: 3.5rem;
        line-height: 4.5rem;
    }
    @media screen and (max-width : 560px) {
        font-size: 4rem;
        line-height: 5rem;
        br {
            display: none;
        }
    }
}

.content {
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}

.yellow-notice {
    display: inline-block;
    color: #000;
    border-radius: 2px;
    background-color: rgb(255, 209, 59);
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 1rem 2rem;
    @media screen and (max-width : 575px) {
        font-size: 14px;
    }
}

.t-input {
    width: 100%;
    position: relative;
    .input-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 3rem;
        width: 15px;
        path {
            fill: #D6D6D6;
            transition: 220ms;
        }
    }
    input {
        width: 100%;
        border: 0;
        border-radius: 8.5rem;
        background-color: rgb(240, 240, 240);
        min-height: 8.5rem;
        color: #000;
        padding: 2rem 7rem;
        margin-bottom: 8px;
        outline: none;
        font-size: 18px;
        @media screen and (max-width : 991px) {
            font-size: 16px;
            min-height: 10rem;
        }
        &::placeholder {
            color: #434343;
            font-size: 14px;
        }
        &:focus {
            & ~ .input-icon path {
                fill: #000;
            }
        }
    }
}

.t-btn {
    background: linear-gradient( 90deg, rgb(255,222,71) 0%, rgb(255,198,0) 5%, rgb(255,224,107) 100%);
    box-shadow: 0px 4px 0px 0px rgba(163, 120, 0, 0.004);
    border-radius: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-bottom: 4px solid #97780C;
    transition: 0;
    width: 100%;
    height: 9rem;
    text-align: center;
    cursor: pointer;
    @media screen and (max-width : 575px) {
        height: 11rem;
    }
    span {
        position: relative;
        z-index: 2;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: #000;
        @media screen and (max-width : 575px) {
            font-size: 13px;
        }
    }
    .bg {
        position: absolute;
        border-radius: 8rem;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient( 90deg, rgb(254,221,86) 0%, rgb(236,172,0) 5%, rgb(250,223,114) 100%);
        background: linear-gradient( -90deg, rgb(255,222,71) 0%, rgb(255,198,0) 5%, rgb(255,224,107) 100%);
        z-index: 1;
        opacity: 0;
        transition: opacity 220ms;
    }
    &:hover {
        .bg {
            opacity: 1;
        }
    }
}

.intro__btn {
    background: linear-gradient( 90deg, rgb(255,222,71) 0%, rgb(255,198,0) 5%, rgb(255,224,107) 100%);
    box-shadow: 0px 4px 0px 0px rgba(163, 120, 0, 0.004);
    border-radius: 8rem;
    padding: 10px;
    padding-right: 40px;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 4px solid #97780C;
    transition: 0;
    span {
        position: relative;
        z-index: 2;
        margin-left: 2rem;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: #000;
    }
    img {
        position: relative;
        z-index: 2;
    }
    .bg {
        position: absolute;
        border-radius: 8rem;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient( 90deg, rgb(254,221,86) 0%, rgb(236,172,0) 5%, rgb(250,223,114) 100%);
        z-index: 1;
        opacity: 0;
        transition: opacity 220ms;
    }
    &:hover {
        // border-bottom: 2px solid #97780C;
        .bg {
            opacity: 1;
        }
    }
}

@keyframes moving {
	from {
		background-position: 0px 0px;
	}
	to {
		background-position: 25px 0px;
	}
}
.w100 {
    width: 100%;
}