#footer {
    position: relative;
    z-index: 4;
    padding: 3.5rem 0 3rem;
    overflow: hidden;
    background: #040404;
    .container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @media screen and (max-width : 991px) {
            max-width: 95%;
        }
    }
   
}


.footer__bottom {
    margin: 2rem 0 0 ;
    .container {
        @media screen and (max-width : 575px) {
            display: block !important;
        }
    }
    .text {
        font-size: 12px;
        color: #fff;
        line-height: 1.2;
        margin: 0;
    }
    a:hover {
        color: #ffe77f;
    }
    .author {
        color: #ffe77f;
        &:hover {
            text-decoration: underline;
        }
    }
    .footer__right {
        text-align: right;
        @media screen and (max-width : 575px) {
            text-align: left;
            margin-top: 20px;
        }
    }
}

#mobile__menu {
    z-index: 100;
    overflow: hidden;
    position: fixed;
    width: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #040404;
    color: #fff;
    box-shadow: 0px 35px 65px rgba(0, 0, 0, 0.45);
    max-width: 350px;
    transition: width 350ms;
    @media screen and (max-width : 767px) {
        max-width: 100%;
    }
    &.active {
        width: 100%;
    }
    .header__logo {
        width: 150px;
    }
    .content {
        padding: 30px 25px 30px;
        height: 100%;
        width: 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        @media screen and (max-width : 767px) {
            width: 100vw;
        }
    }
    .menu__bottom {
        display: none;
        width: 100%;
        flex-direction: column;
        margin: 40px 0 0;
        .t-btn {
            display: block;
            box-shadow: none;
            font-size: 18px;
            padding: 15px 20px;
        }
        @media screen and (max-width : 500px) {
            display: block;
        }
    }
    .menu__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 100%;
        .header__logo {
            display: flex;
            align-items: center;
            width: 170px;
        }
        #mobile__menu-close {
            transition: 220ms;
            cursor: pointer;
            opacity: 0.5;
            width: 20px;
            height: 20px;
            &:hover {
                opacity: 1;
            }
            .img-svg {
                width: 22px;
                height: 22px;
                path {
                    fill: #fff;
                }
            }
        }
    }
    .menu__content {
        flex-grow: 1;
        padding-top: 8rem;
        overflow-y: auto;
    }
    .header__catalog, .header__messenger, .header__email, .header__social, .header__phone {
        display: none;
        width: 100%;
        margin: 0 0 30px;
        padding: 0;
        text-align: left;
    }

    .header__title {
        font-weight: 500;
        font-size: 18px;
    }
    
    .header__catalog {
        @media screen and (max-width : 991px) {
            display: flex;
            span {
                margin-left: 18px;
                font-size: 14px;
            }
        }
    }
    .header__messenger {
        @media screen and (max-width : 575px) {
            display: block;
        }
        .messenger-btn {
            .img-svg {
                width: 20px;
                height: 20px;
            }
            span {
                font-size: 16px;
            }
        }
    }
    .header__email {
        display: block;
        a {
            font-size: 16px;
        }
    }
    .header__social {
        @media screen and (max-width : 991px) {
            display: block;
        }
        .img-svg {
            width: 30px;
            height: 30px;
        }
    }
    .header__phone {
        @media screen and (max-width : 767px) {
            display: block;
        }
        .phone-link {
            margin: 8px 0;
            font-size: 23px;
        }
        .header__status {
            justify-content: flex-start;
        }
        .modal__open {
            font-size: 15px;
        }
    }
    
}