


@font-face {
    font-family: 'Gilroy'; 
    src: url('../fonts/gilroy-regular.woff2') format('woff2'),
    url("../fonts/gilroy-regular.woff") format("woff");
    font-weight: 400; 
    font-display: swap;
    font-style: normal; 
}
@font-face {
    font-family: 'Gilroy'; 
    src: url('../fonts/gilroy-medium.woff2') format('woff2'),
    url('../fonts/gilroy-medium.woff') format('woff');
    font-weight: 500; 
    font-display: swap;
    font-style: normal; 
}
@font-face {
    font-family: 'Gilroy'; 
    src: url('../fonts/gilroy-semibold.woff2') format('woff2'),
    url('../fonts/gilroy-semibold.woff') format('woff');
    font-weight: 600; 
    font-display: swap;
    font-style: normal; 
}
@font-face {
    font-family: 'Gilroy'; 
    src: url('../fonts/gilroy-bold.woff2') format('woff2'),
    url('../fonts/gilroy-bold.woff') format('woff');
    font-weight: 700; 
    font-display: swap;
    font-style: normal; 
}
@font-face {
    font-family: 'Bebas Neue'; 
    src: url('../fonts/ofont.ru_Bebas-Neue.woff2') format('woff2'),
    url('../fonts/ofont.ru_Bebas-Neue.woff') format('woff');
    font-weight: 300; 
    font-display: swap;
    font-style: normal; 
}
@font-face {
    font-family: 'Bebas Neue'; 
    src: url('../fonts/Bebas_Neue_regular.woff2') format('woff2'),
    url('../fonts/Bebas_Neue_regular.woff') format('woff');
    font-weight: 400; 
    font-display: swap;
    font-style: normal; 
}
@font-face {
    font-family: 'Bebas Neue'; 
    src: url('../fonts/bebas-neue-bold.woff2') format('woff2'),
    url('../fonts/bebas-neue-bold.woff') format('woff');
    font-weight: 700; 
    font-display: swap;
    font-style: normal; 
}
@font-face {
    font-family: 'Acrom'; 
    src: url('../fonts/acrom600.woff2') format('woff2'),
    url('../fonts/acrom600.woff') format('woff');
    font-weight: 600; 
    font-display: swap;
    font-style: normal; 
}
